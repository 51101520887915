<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <marketing-nav></marketing-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="row mb-3" id="templates">
            <div class="col">
              <div class="card border-0 shadow-sm">
                <div class="card-header">Email Signature</div>
                <div class="card-body pt-0">
                  <div class="row mb-3">
                    <div class="col">
                      <p class="alert alert-primary mb-3">
                        Set your email signature, shown at the bottom of emails
                        you compose and send to clients through Swandoola.
                      </p>
                      <vue-editor v-model="sig"></vue-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import { VueEditor } from "vue2-editor";
import MarketingNav from "../../partials/MarketingNav";

export default {
  props: [],
  data() {
    return {
      loading: true,
      emailSettings: null,
      sig: this.$store.getters['auth/user'].email_signature,
    };
  },
  watch: {
    sig() {
      this.updateEmailSignature();
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    updateEmailSignature: debounce(function() {
      this.user.email_signature = this.sig;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/marketing/api/" +
            this.user.id +
            "/email-signature",
          this.user
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshUser", data);
        });
    }, 300),
    fetchEmailSettings() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/marketing/api/" +
            this.user.id +
            "/email-settings"
        )
        .then(({ data }) => {
          this.emailSettings = data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchEmailSettings();
  },
  components: {
    MarketingNav,
    VueEditor
  },
};
</script>
